
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';

export const Home = () => {
    return (
            <div>
                <div>
                    <p>A Encant.me nasceu pelo desejo de utilizar a tecnologia do QR Code para eternizar momentos criando lindas lembranças.</p>
                    <br />
                    <p>Damos importância ao que realmente tem valor nessa vida! </p>
                    <br />
                    <p>Transborde todo seu amor e carinho, crie sua homenagem e veja a magia acontecer. Teste pra verificar a atualização</p>
                </div>
                <div>
                    <ReactPlayer controls url="videos/QRCode.mp4" className="video" />
                </div>
                <div className="container">
                    <p className="linha1">Não deixe momentos importantes passarem em branco.</p><br />
                    <p className="linha1">Crie sua homenagem virtual, Conecte-se e Surpreenda-se.</p>

                    <p>
                        <Link to="/login">Começar Agora</Link>
                    </p>
                </div>
            </div>
    );
}